.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.desc {
  color: var(--light-gray-2);
  text-align: center;
  font-size: 1.125rem;
  font-weight: 500;
  line-height: 22px;
  line-height: 1.75rem; /* 155.556% */
  max-width: 587px;
  margin-bottom: 4rem;
}

.cardContainer {
  /* margin-top: 64px; */
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  gap: 20px;
  justify-content: space-around;
  margin-bottom: 4rem;
}

.singleCard {
  display: flex;
  flex: 1;
  flex-direction: column;
  border-radius: 20px;
  min-width: 330px;
  max-width: 500px;
  justify-content: center;
  align-items: center;
  border: 1.5px solid rgba(255, 255, 255, 0.7);
  padding: 60px 10px 90px 10px;
}
.header {
  text-align: center;
  margin-bottom: 1.5rem;
}
.cardImage {
  margin-bottom: 70px;
}
.cardTitle {
  color: #fff;
  text-align: center;
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.5rem; /* 142.857% */
}

.cardBtn {
  font-size: 1rem;
  margin-top: 40px;
  border-radius: 52px;
  background: rgba(255, 255, 255, 0.1);
  padding: 0.75rem 1.5rem;
  cursor: pointer;
  color: white;
  font-weight: 500;
  line-height: 1.25rem; /* 125% */
}

.cardContainer:first-child {
  padding: 50px 0;
}

@media only screen and (max-width: 500px) {
  .desc {
    font-size: 16px;
  }
  .cardBtn {
    font-size: 14px;
  }
}
