.heroContainer {
  padding: 120px 0;
}
.topLeftText {
  width: 50%;
  max-width: 521px;
  position: relative;
}
.colorP50 {
  color: var(--p-50);
}
.title {
  width: 85%;
}
.topParaTextContainer {
  margin-top: 24px;
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.btnIconBox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 32px;
  height: 32px;
  border-radius: 12px;
}
.actions {
  margin-top: 48px;
  display: flex;
  align-items: center;
  gap: 24px;
}
.bookDemoBtn {
  border-radius: 16px;
  color: var(--p-50);
  background-color: var(--p-10);
  padding: 20px 24px;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-600);
}
.textSecondary {
  color: var(--text-secondary);
}

@media (min-width: 800px) and (max-width: 1200px) {
  .topLeftText {
    flex-shrink: 0;
  }
}

@media screen and (max-width: 800px) {
  .heroContainer {
    padding: 80px 24px;
  }
  .topParaTextContainer {
    margin-top: 24px;
    width: 100%;
  }
  .bookDemoBtn {
    display: none;
  }
}

@media screen and (max-width: 600px) {
  .topLeftText {
    width: 100%;
    text-align: center;
  }
  .title,
  .topParaTextContainer {
    width: 100%;
  }
  .actions {
    justify-content: center;
    gap: 8px;
  }
}
