.container {
  width: 100%;
  background-color: #ebf5fe;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.subContainer {
  max-width: var(--container-width-primary);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  text-align: center;
}
.cardContainer {
  display: flex;
  width: 100%;
  justify-content: space-around;
  align-items: flex-start;
  gap: 30px;
  flex-wrap: wrap;
}
.singleCard {
  display: flex;
  width: 28%;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
}
.title {
  color: var(--black);
  font-size: 21px;
  text-align: center;
  font-weight: 700;
  line-height: 32px;
  margin-top: 20px;
}
.subTitle {
  color: var(--black);
  text-align: center;
  font-size: 18px;
  font-weight: 400;
  line-height: 24px;
  opacity: 0.7;
}

@media only screen and (max-width: 800px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
  .singleCard {
    width: 40%;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
}

@media only screen and (max-width: 500px) {
  .singleCard {
    width: 80%;
  }
}

@media only screen and (max-width: 1400px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
}
