
.container {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}
.topTitleBox {
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.header {
  display: flex;
  align-items: center;
  gap: 16px;
}
.desc {
  color: var(--text-primary);
  font-size: var(--font-size-20);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-28);
  letter-spacing: 0.2px;
}
.progressBarParentContainer {
  /* width: 45%; */
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  gap: 64px;
}
.progressListWrapper {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.singleProgressContainer {
  transition: all 400;
  cursor: pointer;
  height: 30px;
}
.singleProgressContainerActive {
  height: auto;
  transition: all 400;
}

.progressTitleText {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}
.activeProgressTitleText {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-30);
  letter-spacing: -0.4px;
}
.progressDetailsContainer {
  display: flex;
  gap: 16px;
}
.inActiveLoaderBar {
  width: 3px;
  background-color: var(--white);
  height: 30px;
  border-radius: 16px;
}
.activeProgressDetails {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: calc(100% - 3px);
}
.progressDesc {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-400);
  line-height: var(--line-height-24);
  letter-spacing: 0.2px;
}

.slidesContainer {
  display: flex;
  width: 100%;
  gap: 90px;
  align-items: center;
}
.imageParentContainer {
  width: 55%;
}

.slideImage {
  object-fit: contain;
  width: 100%;
  height: 100%;
}
.swiperSlide {
  border-radius: 20px;
  background-color: transparent;
}
.singleCardContainer {
  border-radius: 22px;
  display: flex;
  width: 100%;
  height: 100%;
  min-height: 537px;
}

.cardImg {
  width: 100%;
  height: 100%;
  object-fit: fill;
}

.progressContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  width: 100%;
}

.progressBarContainer {
  width: 3px;
  max-height: 202px;
  position: relative;
  /* overflow: hidden; */
  border-radius: 16px;
  background: var(--white);
}
.progressBar {
  width: 3px;
  max-height: 202px;
  height: 100%;
  background: var(--white);
  opacity: 0.1;
}
.progressBarPseudo {
  position: absolute;
  width: 3px;
  max-height: 202px;
  height: 100%;
  z-index: 12;
  top: 0;
}
.mobileSlideImage {
  display: none;
}

@media screen and (max-width: 1284px){
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .slidesContainer {
    flex-direction: column;
  }
  .progressBarParentContainer {
    width: 100%;
  }
  .mobileSlideImage {
    display: block;

  }
  .imageParentContainer {
    display: none;
  }
  .progressBarContainer, .progressBarPseudo, .progressBar {
    max-height: fit-content;
  }
  .container {
    max-width: 100%;
    padding: 7.5rem 24px;
  }
}
