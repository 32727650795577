.mainContainer {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
}
.paddingMainContainer {
  padding: 120px 0;
}
.carouselPadding {
  padding: 48px 0;
}
.topTileDetails {
  display: flex;
  flex-direction: column;
  gap: 24px;
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
}
.innerTopDetails {
  display: flex;
  flex-direction: column;
  gap: 12px;
}
.cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 32px;
  margin: 48px 0;
}
.card img {
  height: auto;
}
.card {
  background-color: var(--white);
  border-radius: 16px;
}
.cardText {
  padding: 16px;
  color: #191653;
}
.cardIconBox {
  width: 68px;
  height: 68px;
  background-color: var(--p-10);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  border-radius: 16px;
  margin: 16px 16px 0 16px;
}
.footerText {
  max-width: 740px;
  margin: 0 auto;
  color: var(--p-50);
  text-align: center;
}
.bgFillSelection {
  background-color: var(--fill-selection-only);
}
.bgFillHighlight {
  background-color: var(--fill-highlight-only);
}
.zenHrisActiveBgColor {
  background-color: var(--p-50);
}
.zenHrisActiveColor {
  color: var(--p-50);
}
.loaderBarColor {
  background-color: var(--p-10);
}

@media (min-width: 800px) and (max-width: 1284px) {
  .mainContainer {
    max-width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .paddingMainContainer {
    padding: 80px 24px;
  }
  .cardContainer {
    display: flex;
    flex-direction: column;
  }
  .card {
    max-width: 100%;
  }
  .cardText {
    line-height: 24px;
  }
}
