.mainFooterContainer {
  overflow-x: hidden;
}
.reviewContainer {
  background-color: var(--p-50);
  padding: 80px 0 1px 0;
}
.footer {
  background-color: var(--p-90);
  color: var(--white);
  padding-top: 80px;
  position: relative;
}
.footerSpacing {
  margin-top: 134px;
  padding-top: 180px;
}
.footerSpacing1 {
  margin-top: 154px;
  padding-top: 180px;
}
.footerImageCard {
  position: absolute;
  padding: 48px 0;
  min-height: 200px;
  height: auto;
  left: 50%;
  top: 0%;
  transform: translate(-50%, -50%);
  background-color: var(--p-50);
  max-width: 1284px;
  width: 70%;
  border-radius: 10px;
  display: flex;
  align-items: center;
  z-index: 4;
}
.imageCardTop60 {
  top: -0%;
}
.imageCardTop40 {
  top: -0%;
}
.btnIconBox {
  background-color: var(--p-50);
  border-radius: 12px;
  width: 32px;
  height: 32px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
.marqueeContainer {
  height: 100px;
}
.textContainer {
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  height: 100%;
  /* padding: 0 20rem 0 15rem; */
}
.whiteCardTextContainer {
  max-width: var(--max-screen-width);
  margin: 0 auto;
  background-color: #fff;
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 40px;
}
.whiteCardTextContainer .whiteCardTextDetails {
  text-align: center;
  gap: 16px;
}
.whiteCardTextContainer .btnIconBox {
  background-color: var(--white);
  border-radius: 16px;
  width: 48px;
  height: 48px;
}
.textSecondary {
  color: var(--text-secondary);
}
.footerContainer {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding-bottom: 81px;
  border-top: 1px solid var(--p-80);
}
.topLeftText {
  font-size: var(--font-size-16);
  line-height: var(--line-height-26);
  font-weight: var(--font-weight-400);
  color: var(--text-tertiary);
}
.footerHeader {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.footerHeaderDetails {
  max-width: 428px;
  width: 40%;
}
.softwareIconsContainer {
  padding: 80px 0;
  display: flex;
  flex-direction: column;
  gap: 4rem;
  position: relative;
}
.softwareIconsContainer::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    to right,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.5) 10%,
    rgba(255, 255, 255, 0) 25%,
    rgba(255, 255, 255, 0) 75%,
    rgba(255, 255, 255, 0.5) 90%,
    rgba(255, 255, 255, 1) 100%
  );
  pointer-events: none;
}
.swIconBox {
  height: 80px;
  width: 80px;
  display: inline-flex;
  flex-shrink: 0;
  flex-grow: 0;
  margin-right: 8px;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 3px 10px 0px #00000014;
  border-radius: 24px;
}
.explorelinkButton {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: var(--p-50);
  font-size: var(--font-size-18);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-23);
  cursor: pointer;
}
.explorelinkButton:hover {
  text-decoration: underline;
}

.footerTitle {
  text-align: center;
  max-width: 440px;
  width: 90%;
  margin: 0 auto;
}
.heading {
  font-size: var(--font-size-h5);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-30);
}
.rowTitle {
  color: var(--text-tertiary);
  font-size: var(--font-size-h7);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-20);
}
.list {
  font-size: var(--font-size-16);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-22);
  letter-spacing: 0.2px;
}
.footerSection {
  margin-top: 81px;
}
.footerListContainer {
  display: flex;
  justify-content: space-between;
  margin-top: 32px;
  gap: 32px;
}
.productsFootListContainer {
  flex-direction: column;
}
.solutionFootListContainer {
  justify-content: revert;
  gap: 6%;
}
.mt32 {
  margin-top: 32px;
}
.listContainer {
  max-width: 216px;
  width: 100%;
  display: flex;
  /* flex-direction: column; */
  gap: 24px;
}
.productListContainer {
  max-width: 100%;
  flex-direction: row;
  justify-content: space-between;
}
.productsListContainer {
  max-width: 100%;
  gap: 48px;
}
.solutionListContainer {
  max-width: 320px;
}
.companyListContainer {
  max-width: 180px;
  width: 100%;
}
.addressListContainer {
  max-width: 317px;
}
.footerRowContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.footerRow {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.hiringFlagText {
  display: inline-block;
  background: #d7f0e529;
  border-radius: 8px;
  padding: 2px 6px;
  color: var(--g-50);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-18);
}
.comingLetterFlag {
  color: var(--text-tertiary);
}
.comingLetterFlag p {
  margin-top: 8px;
  display: inline-block;
  border-radius: 8px;
  padding: 2px 6px;
  background: #3498db29;
  color: var(--i-50);
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-18);
}
.footerBottomDetails {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 88px;
  margin-top: 81px;
  border-top: 1px solid var(--p-80);
}
.footerCardTextContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 60%;
}
.footerCardText span {
  display: block;
}
.flexCenter {
  display: flex;
  align-items: center;
}
.gap12 {
  gap: 12px;
}
.policyText {
  gap: 40px;
  font-size: var(--font-size-14);
  font-weight: var(--font-weight-500);
  line-height: var(--line-height-18);
}

.imageHidden {
  display: none;
}

.imageVisible {
  display: block;
}

.footerBottomLogo {
  display: flex;
  align-items: center;
  gap: 8px;
}

.scrollImageContainer img {
  max-width: 100%;
  height: auto;
}

.lastFooterImage {
  width: 100%;
  height: auto;
}

@media screen and (max-width: 1284px) {
  .footerContainer {
    max-width: 90%;
  }
  .footerImageCard {
    width: 70%;
  }
}

@media screen and (max-width: 800px) {
  .footerContainer {
    border: none;
  }
  .footerListContainer {
    flex-direction: column;
    gap: 48px;
  }
  .solutionFootListContainer {
    gap: 24px;
  }
  .footerSection {
    margin-top: 48px;
  }
  .footerBottomDetails {
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    gap: 40px;
    padding-top: 40px;
    margin-bottom: 120px;
  }
  .softwareIconsContainer::after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    pointer-events: none;
  }
  .textContainer {
    flex-direction: column;
    height: 100%;
    align-items: flex-start;
    justify-content: space-between;
    gap: 32px;
  }
  .footerImageCard {
    width: 90%;
    padding: 24px;
  }
  .footerCardTextContainer {
    width: 100%;
  }
  .imageCardTop40 {
    top: -0%;
  }
  .imageCardTop60 {
    top: -0%;
  }
  .whiteCardTextContainer {
    width: 100%;
    padding: 80px 24px;
  }
  .productsListContainer {
    flex-direction: column;
    gap: 24px;
  }
  .footerRow {
    gap: 32px;
  }
}

@media (min-width: 550px) and (max-width: 650px) {
  .footerSpacing {
    padding-top: 32%;
    margin-top: 36%;
  }
}
@media (min-width: 400px) and (max-width: 550px) {
  .footerSpacing {
    padding-top: 36%;
    margin-top: 36%;
  }
}

@media screen and (max-width: 500px) {
  .swIconBox {
    height: 68px;
    width: 68px;
  }
  .marqueeContainer {
    height: 82px;
  }
  .footerTitle {
    padding: 0 24px;
  }
  .footerImageCard {
    height: auto;
    width: calc(100% - 48px);
  }
  .textContainer {
    padding: 80px 24px;
    gap: 32px;
  }
  .footerSection {
    border-top: 1px solid var(--p-80);
    padding-top: 48px;
  }
  .footerCardText span {
    display: inline;
  }
  .footerSpacing {
    padding-top: 65%;
    margin-top: 65%;
  }
}
@media screen and (max-width: 400px) {
  .footerSpacing {
    padding-top: 72%;
    margin-top: 72%;
  }
}

@media screen and (max-width: 340px) {
  .footerSpacing {
    padding-top: 78%;
    margin-top: 78%;
  }
}
