.container {
  width: 100%;
  background-color: #ebf5fe;
  padding-top: 7.5rem;
  padding-bottom: 7.5rem;
}

.subContainer {
  max-width: var(--container-width-primary);
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 6rem;
  text-align: center;
}
.cardContainer {
  display: flex;
  width: 100%;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 4rem;
}
.singleCard {
  max-width: 1051px;
  border-radius: 10px;
  border: 1px solid #ebf5fe;
  background: #fff;
  padding: 10px 104px;
  color: #191653;
  font-size: 2.2rem;
  font-weight: 500;
  line-height: 50px;
}

@media only screen and (max-width: 800px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
  .singleCard {
    width: 100%;
    padding: 10px 20px;
  }
}
@media only screen and (min-width: 800px) and (max-width: 1200px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
}

@media only screen and (max-width: 500px) {
  .singleCard {
    width: 100%;
    padding: 10px 20px;
  }
}

@media only screen and (max-width: 1400px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
}
