.parentContainer {
  overflow: hidden;
}

.container {
  width: 100%;
  background-color: #0d0d0e;
  /* padding-top: 4rem; */
}
.subContainer {
  max-width: var(--container-width-primary);
  margin: 0 auto;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  /* position: relative; */
}
.navContainer {
  width: 100%;
  background: transparent;
  padding: 30px 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  z-index: 109;
}
.heroContainer {
  margin-top: 5rem;
}
.navBarBlurBg {
  position: absolute;
  z-index: 100;
  height: 170px;
  width: 850px;
  right: 20%;

  /* overflow: hidden; */
  /* border: 1px solid green; */
  background: linear-gradient(
        to bottom right,
        rgba(34, 64, 205, 0.71) 16%,
        rgba(2, 94, 102, 0.77) 34%,
        rgba(37, 77, 218, 0.33) 47%
      )
      bottom right / 50% 50% no-repeat,
    linear-gradient(
        to bottom left,
        rgba(34, 64, 205, 0.71) 16%,
        rgba(2, 94, 102, 0.77) 34%,
        rgba(37, 77, 218, 0.33) 47%
      )
      bottom left / 50% 50% no-repeat,
    linear-gradient(
        to top left,
        rgba(34, 64, 205, 0.71) 16%,
        rgba(2, 94, 102, 0.77) 34%,
        rgba(37, 77, 218, 0.33) 47%
      )
      top left / 50% 50% no-repeat,
    linear-gradient(
        to top right,
        rgba(34, 64, 205, 0.71) 16%,
        rgba(2, 94, 102, 0.77) 34%,
        rgba(37, 77, 218, 0.33) 47%
      )
      top right / 50% 50% no-repeat;
  filter: blur(80px) !important;
  -webkit-backdrop-filter: blur(0px) !important;
  /* backdrop-filter: blur(80px) !important; */
}
.firstSection {
  display: flex;
  flex-direction: column;
  width: 65%;
  position: relative;
  z-index: 102;
  background-color: transparent;
}
.topTitle {
  margin-bottom: 2.94rem;
}

.desc {
  width: 90%;
  color: #fff;
  font-size: 1.3rem;
  font-weight: 400;
  line-height: 36px;
  letter-spacing: -0.22px;
  margin-top: 3.19rem;
}
.link {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  width: fit-content;
  border-radius: 37px;
  border: 1px solid rgba(37, 77, 218, 0.35);
  background: rgba(37, 77, 218, 0.05);
  margin-top: 70px;
  padding-left: 20px;
}
.costOptimizeText {
  color: white;
}
.imgContainer {
  /* top: -30px; */
  /* max-width: 55%; */
  position: relative;
  /* right: 0; */
  z-index: 100;
}
.heroImage {
  width: 50.875rem;
  height: auto;
  max-width: none;
  position: absolute;
  top: -27rem;
  bottom: auto;
  left: -15rem;
  right: auto;
  object-fit: contain;
}
.heroImageNoExtraSection {
  top: -29rem;
}
.secondContainer {
  width: 100%;
  background-color: #0d0d0e;
  padding-top: 14.5rem;
  /* padding-bottom: 3rem; */
  margin-bottom: 300px;
}
.background {
  background: linear-gradient(
    259deg,
    rgba(37, 77, 218, 0.63) 26.03%,
    rgba(52, 67, 131, 0.71) 51.19%,
    rgba(2, 94, 102, 0.6) 78.37%
  );
  filter: blur(100px);
  height: 150px;
}

.secondSubContainer {
  max-width: var(--container-width-primary);
  background-color: transparent;
  margin: 0 auto;
  width: 100%;
  display: flex;
  /* padding-bottom: ; */
  justify-content: space-around;
  align-items: center;
  position: relative;
  /* padding-top: 3rem; */

  /* padding-bottom: 5rem; */
  /* margin-bottom: 4rem; */
}

.absoluteImage {
  position: absolute;
  z-index: 102;
  top: -200px;
  left: 0;
  object-fit: contain;
}
.graphImage {
  object-fit: contain;
}

.lastImageContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  background-color: transparent;
  position: relative;
  z-index: 200;
  top: 25rem;
}
.lastSectionCard {
  display: flex;
  /* flex: 1; */

  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 10px 36px;
  border-radius: 6.914px;
  background: #fff;
  box-shadow: -2px -1px 14.8px 0px rgba(0, 0, 0, 0.15);
  min-width: 211px;
  min-height: 183px;
  width: 23%;
  gap: 28px;
  padding-bottom: 44.5px;
}
.lastImageTitle {
  color: var(--blue-1);
  font-size: 0.84925rem;

  font-weight: 600;
  line-height: 1.54413rem; /* 181.818% */
}
.images {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 24px;
}
.lastImage {
  width: 288px;
  height: 163px;
}
.lastImage1,
.lastImage2 {
  margin-top: 2rem;
}

@media only screen and (max-width: 1000px) {
  .navContainer {
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  .imgContainer {
    display: none;
  }
  .imgContainer img {
    display: none;
  }
  .firstSection {
    width: var(--container-responsive-width);
  }
  .navBarBlurBg {
    max-width: 80%;
    right: 0;
  }
}
.companyLogo {
  height: 45px;
  width: auto;
}
/* Extra small devices (phones, 576px and down) */
@media only screen and (max-width: 800px) {
  .subContainer {
    flex-direction: column;
    width: 100%;
  }
  .navContainer {
    width: var(--container-responsive-width);
    margin: 0 auto;
    padding-left: 0;
    padding-right: 0;
  }
  .firstSection {
    width: var(--container-responsive-width);
  }
  .imgContainer {
    display: none;
  }
  .navBarBlurBg {
    max-width: 80%;
    right: 0;
  }
}

@media only screen and (min-width: 800px) and (max-width: 1250px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
  .navBarBlurBg {
    max-width: 80%;
    right: 0;
  }
}

@media only screen and (min-width: 1200px) {
  .navBarBlurBg {
    max-width: 80%;
    right: 0;
  }
}

@media only screen and (max-width: 1350px) {
  .lastImageContainer {
    width: var(--container-responsive-width);
  }
}

@media only screen and (max-width: 1000px) {
  .lastImageContainer {
    width: var(--container-responsive-width);
    gap: 1.08rem;
    align-items: normal;
  }
  .lastSectionCard {
    width: 48%;
  }
  .lastImage1,
  .lastImage2 {
    margin-top: 0;
  }
}

@media only screen and (max-width: 600px) {
  .heroContainer {
    margin-top: 1.7rem;
  }
  .topTitle {
    margin-bottom: 1.7rem;
  }
  .header {
    font-size: 44px;
    line-height: 1;
  }
  .desc {
    line-height: 1.5;
    width: 90%;
    margin-top: 1.5rem;
  }
  .link {
    margin-top: 30px;
  }
  .parentContainerMob {
    padding-bottom: 70rem !important;
    overflow: hidden;
  }
  .lastImageContainer {
    width: var(--container-responsive-width);
    gap: 1.08rem;
    justify-content: center;
    align-items: normal;
    margin-top: 22rem;
  }
  .lastSectionCard {
    width: 98%;
    min-height: auto;
  }
  .lastImage1,
  .lastImage2 {
    margin-top: 0;
  }
  .secondContainer {
    padding: 0;
    margin: 0;
    margin-top: -10px;
  }
  .secondSubContainer {
    height: 3rem;
  }
  .graphImage {
    margin-top: -100px;
  }
  .lastImageTitle {
    font-size: 14px;
    padding-top: 1.24rem;
  }
}

@media only screen and (max-width: 1100px) {
  .heroImage {
    width: 45rem;
  }
}
@media only screen and (min-width: 1300px) and (max-width: 1450px) {
  .heroImage {
    width: 52rem;
  }
}
@media only screen and (min-width: 1450px) {
  .heroImage {
    width: 58rem;
  }
}

@media only screen and (max-width: 1400px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
}

@media only screen and (max-width: 500px) {
  .companyLogo {
    height: 35px;
    width: auto;
  }
}

@media only screen and (max-width: 400px) {
  .companyLogo {
    height: 35px;
    width: auto;
  }
}

@media only screen and (max-width: 350px) {
  .companyLogo {
    height: 30px;
    width: auto;
  }
}
