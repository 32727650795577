.mainContainer {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
}
.paddingMainContainer {
  padding: 120px 0;
}
.container {
  padding: 80px 0;
}
.container2 {
  padding: 80px 0 120px 0;
}
.container3 {
  padding: 80px 0 0;
}
.topTileDetails {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
}
.bgFillSelection {
  background-color: var(--fill-selection-only);
}
.zenHrisActiveBgColor {
  background-color: var(--p-50);
}
.zenHrisActiveColor {
  color: var(--p-50);
}
.loaderBarColor {
  background-color: var(--p-10);
}

@media (min-width: 800px) and (max-width: 1284px) {
  .topTileDetails {
    max-width: 90%;
  }
  .container, .container2, .container3 {
    max-width: 90%;
    margin: 0 auto;
  }
}

@media screen and (max-width: 800px) {
  .topTileDetails {
    padding: 0 24px;
  }
  .paddingMainContainer {
    padding: 80px 0;
  }
  .container, .container2 {
    padding: 80px 24px;
  }
  .container3 {
    padding: 80px 24px 0 24px;
  }
}