.heroSection {
  margin-top: 68px;
  min-height: calc(100vh - 68px);
  height: auto;
  width: 100%;
  padding-bottom: 80px;
  background-image: url('../../../../assets/icons/ga/hero-bg.webp');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.heroSectionContainer {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding: 80px 0 120px 0;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 80px;
}

.heroMarqueeContainer {
  gap: 50px;
}
.marqueeLogoBox {
  /* margin-right: 50px; */
  height: 32px;
  width: 180px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.marqueeLogoBox img {
  width: 100%;
}

@media (min-width: 1440px) {
  .heroSection {
    min-height: auto;
    height: auto;
  }
}

@media (min-width: 800px) and (max-width: 1200px) {
  .heroSectionContainer {
    max-width: 90%;
  }
}

@media screen and (max-width: 800px){
  .heroSectionContainer {
    flex-direction: column;
  }
}

@media screen and (max-width: 650px){
  .heroSection {
    margin-top: 56px;
  }
  .heroSectionContainer {
    padding: 80px 24px;
  }
}
