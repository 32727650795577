.bgFillSelection {
  background-color: var(--fill-selection-only);
}
.container {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding: 120px 0;
}
.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 48px;
  gap: 24px;
}
.card {
  width: 32%;
  padding: 16px;
  border-radius: 16px;
  display: inline-flex;
  flex-direction: column;
  flex-shrink: 0;
  gap: 16px;
  background-color: var(--white);
}
.cardTextContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.iconContainer {
  width: 68px;
  height: 68px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--p-10);
  border-radius: 16px;
}
.title {
  text-align: center;
}

@media screen and (max-width: 800px) {
  .container {
    padding: 80px 24px;
  }
  .cardContainer {
    flex-direction: column;
  }
  .card {
    width: 100%;
  }
}
