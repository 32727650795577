/* .mainSectionContainer {
  margin-top: 200px;
} */
.title {
  text-align: center;
  margin-bottom: 80px;
}

.productListSection {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding: 120px 0;
}

.benifitTopBox {
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 40px;
}
.benifitCardsContainer {
  display: flex;
  gap: 16px;
  width: 100%;
  margin: 0 auto;
}
.gap24 {
  gap: 24px;
}
.productCard {
  width: calc(33.33% - 8px);
  border-radius: 20px;
  border: 1px solid;
  word-wrap: break-word;
}
.productCardHeader {
  border-bottom: 1px solid;
  padding: 24px;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 16px;
}
.productCardBox {
  padding: 24px 16px;
}

.benifitListSection {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding: 80px 0;
}
.benifitCard {
  width: calc(33.33% - 8px);
  background-color: var(--fill-selection-only);
  padding: 40px 32px;
  border-radius: 16px;
  word-wrap: break-word;
}
.benifitCard img {
  width: 68px;
  margin-bottom: 24px;
}
.mt16 {
  margin-top: 16px;
}
.bgW10 {
  background-color: var(--w-10);
}
.borderW50 {
  border-color: var(--w-50);
}
.bgG10 {
  background-color: var(--g-10);
}
.borderG50 {
  border-color: var(--g-50);
}
.bgI10 {
  background-color: var(--i-10);
}
.borderI50 {
  border-color: var(--i-50);
}
.bgFillSelection {
  background-color: var(--fill-selection-only);
}
.colorP50 {
  color: var(--p-50);
}
.bgP10 {
  background-color: var(--p-10);
}
.bgP50 {
  background-color: var(--p-50);
}
@media (min-width: 800px) and (max-width: 1284px) {
  .benifitListSection {
    max-width: 90%;
  }
}

@media (min-width: 600px) and (max-width: 800px) {
  .benifitListSection {
    padding: 80px 24px;
  }
  .benifitCardsContainer {
    flex-direction: column;
  }
  .benifitCard, .productCard {
    width: 100%;
  }

}

@media screen and (max-width: 600px) {
  .benifitListSection {
    padding: 80px 24px;
  }
  .benifitCardsContainer {
    flex-direction: column;
  }
  .benifitCard, .productCard {
    width: 100%;
  }
}
