.container {
  display: flex;
  flex-direction: column;
  border-radius: 20px;
  background: #151335;
  padding: 50px;
  padding-bottom: 1.89rem;
  padding-top: 7.5rem;
}

.firstRow {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 4rem;
}

.firstSection {
  max-width: 412px;
}

.inputLabel {
  color: var(--light-gray-2);
  font-size: 1.25rem;
  line-height: 1.75rem; /* 140% */
  font-weight: 400;
  line-height: 28px;
}
.inputContainer {
  margin-top: 28px;
  width: 100%;
  background-color: white;
  border-radius: 60px;
  display: flex;
  position: relative;
  padding: 6px 12px 6px 25px;
  justify-content: space-between;
  max-width: 500px;
}
.error {
  margin-top: 1rem;
  height: 1rem;
  font-size: 0.8rem;
  color: rgb(234, 94, 94);
  padding-left: 30px;
}
.inputBox {
  all: unset;
  width: 70%;
}
.inputBox::placeholder {
  color: rgba(0, 0, 0, 0.4);
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 20px;
}
.secondSection {
  max-width: 500px;
  display: flex;
  gap: 50px;
}

.singleList {
  display: flex;
  flex-direction: column;
  max-width: 150px;
  gap: 12px;
}

.listTitle {
  color: rgba(255, 255, 255, 0.34);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  margin-bottom: 5px;
}
.singleLink {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem; /* 142.857% */
  cursor: pointer;
}

.subFooter {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* margin-top: 2.125rem; */
  gap: 2rem;
}

.meta {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 21px;
  font-size: 0.875rem;
  line-height: 1.25rem;
  letter-spacing: 0.2px;
  color: #ffffff;
}

.branding {
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.0125rem;
}

.meta .metaLinks {
  display: flex;
  flex-wrap: wrap;
  gap: 24px;
  font-size: 0.875rem;
  line-height: 1.25rem; /* 142.857% */
  letter-spacing: 0.0125rem;
}

.meta .metaLinks .metaLink {
  cursor: pointer;
  text-decoration-line: none;
  color: #fff;
}

.socials {
  display: flex;
  gap: 18px;
  align-items: center;
}
.addressParent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 2.17rem;
  padding-top: 4rem;
}
.addressContainer {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 2rem;
  margin: 4.19rem 0 3.75rem 0;
}
.addressTop {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.75rem;
}
.addressTitle {
  color: #fff;
  font-size: 0.875rem;
  font-style: normal;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
  min-width: 90px;
}
.singleAddress {
  display: flex;
  min-width: 210px;
  flex-direction: column;
  justify-self: flex-start;
  gap: 20px;
}
.countryName {
  color: var(--white);
  font-size: 0.875rem;
  font-weight: 700;
  line-height: 1.75rem; /* 200% */
}
.addressDetails {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.addressLine {
  color: var(--white);
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.5rem; /* 171.429% */
}

.addressImage {
  object-fit: contain;
  max-width: 30%;
}
@media only screen and (max-width: 1150px) {
  .firstSection,
  .secondSection {
    width: 100%;
    justify-content: center;
    max-width: 100%;
  }
  .secondSection {
    justify-content: space-around;
  }
}

@media only screen and (max-width: 450px) {
  .container {
    padding-left: 30px;
  }
  .secondSection {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}

@media only screen and (max-width: 800px) {
  .listTitle,
  .countryName {
    font-size: 1.2rem;
  }
  .secondSection {
    flex-direction: column;
  }
  .singleLink,
  .addressLine,
  .metaLink,
  .branding {
    font-size: 1rem;
  }
  .singleList {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    gap: 12px;
  }
}
@media only screen and (max-width: 500px) {
  .secondSection {
    flex-direction: column;
  }
  .singleList {
    display: flex;
    flex-direction: column;
    max-width: 100%;
    gap: 12px;
  }
  .container {
    padding-right: 30px;
    padding-left: 30px;
  }
  .addressTitle {
    margin-left: 1.4rem;
  }
}

@media only screen and (max-width: 1401px) and (min-width: 1150px) {
  .addressContainer {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    justify-content: flex-start;
  }
}