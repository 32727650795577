.marqueeContainer {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  width: 200%;
}
.iconBoxClass {
  flex-shrink: 0;
  flex-grow: 0;
}

@media screen  and (max-width: 1400px){
  .marqueeContainer {
    width: 250%;
  }
}

@media screen  and (max-width: 1250px){
  .marqueeContainer {
    width: 300%;
  }
}

@media screen  and (max-width: 1000px){
  .marqueeContainer {
    width: 350%;
  }
}

@media screen  and (max-width: 850px){
  .marqueeContainer {
    width: 400%;
  }
}

@media screen  and (max-width: 650px){
  .marqueeContainer {
    width: 500%;
  }
}

@media screen  and (max-width: 500px){
  .marqueeContainer {
    width: 800%;
  }
}