.container {
  position: fixed;
  width: 100%;
  height: 100%;
  padding: 2rem 5rem;
  bottom: 0;
  z-index: 1200;
  max-height: 120px;
  background-color: black;
  color: white;
  display: flex;
  justify-content: center;
  gap: 2rem;
  align-items: center;
  line-height: 1.2;
}
.container a {
  color: #5171E1;
}
.container a:hover {
  text-decoration: underline;
}
.button {
  padding: 12px 24px;
  border-radius: 11px;
  background: #5171E1;
  color: white;
  border-radius: 10px;
}

@media only screen and (max-width: 1000px) {
  .cookieImg {
    height: 70px;
  }
}

@media only screen and (max-width: 600px) {
  .container {
    padding: 2rem 1rem;
  }
  .cookieImg {
    height: 50px;
  }
}
