.zenItActiveColor {
  color: var(--p-50);
}
.zenItActiveBgColor {
  background-color: var(--p-50);
}

.bgFillHighlight {
  background-color: var(--fill-highlight-only);
}
.container {
  max-width: var(--max-screen-width);
  margin: 0 auto;
  padding: 120px 0;
}
.mainContainer {
  display: flex;
  flex-direction: column;
  gap: 40px;
}
.mainTextContainer {
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.mainContainer img {
  height: auto;
}
.mainCardContainer {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 16px;
}
.mainCard {
  background-color: var(--fill-selection-only);
  padding: 24px 16px;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.container2 {
  max-width: 1066px;
  margin: 0 auto;
  padding: 120px 0;
  text-align: center;
}
.cardContainer {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 24px;
  margin-top: 48px;
}
.card {
  padding: 40px 32px;
  border-radius: 14px;
  text-align: left;
}
.bgI10 {
  background-color: var(--i-10);
}
.bgP10 {
  background-color: var(--p-10);
}
.fdColumn {
  display: flex;
  flex-direction: column;
}
.gap120 {
  gap: 120px;
}
.gap40 {
  gap: 40px;
}
.gap16 {
  gap: 16px;
}
.gap12 {
  gap: 12px;
}

@media screen and (max-width: 800px) {
  .container,
  .container2 {
    padding: 80px 24px;
  }
  .cardContainer,
  .mainCardContainer {
    display: flex;
    flex-direction: column;
  }
}
