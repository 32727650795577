.whyZenadminBg {
  background-color: var(--fill-selection-only);
}
.whyZenadminBgContainer {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding: 120px 0;
  text-align: center;
}

/* Top Section */
.topSection {
  margin-bottom: 64px;
  display: flex;
  flex-direction: column;
  gap: 12px;
}


/* Centered Image Details */
.centeredDetailContainer {
  display: flex;
  align-items: center;
  background-color: var(--p-10);
  border-radius: 14px;
}
.detailsContainer {
  width: 45%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 40px;
  padding: 0px 6%;
  text-align: left;
}
.detailsCard {
  display: flex;
  flex-direction: column;
  gap: 24px;
}
.detailsTitle {
  color: var(--p-50);
}
.imageContainer {
  width: 55%;
  height: 490px;
  margin-top: 80px;
}
.image {
  width: 100%;
  height: 100%;
}
.buttonContainer {
  margin-top: 24px;
  display: flex;
  gap: 10px;
  justify-content: center;
}

.button {
  padding: 16px;
  height: 55px;
  background: transparent;
  color: var(--text-secondary);
  border: 1px solid var(--p-10);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  font-size: var(--font-size-18);
  line-height: var(--line-height-23);
  font-weight: var(--font-weight-500);
  flex-shrink: 0;
}

.button.active {
  color: var(--p-50);
  border: 1px solid var(--p-50);
  background: linear-gradient(
    90deg,
    rgba(211, 219, 248, 0.7) 0%,
    rgba(211, 219, 248, 0.7) 50%,
    #ffffff 50%
  );
  background-size: 200% 200%;
  animation: gradientAnimation 8s ease-in-out forwards;
}

@keyframes gradientAnimation {
  from {
    background-position: bottom right;
  }
  to {
    background-position: top left;
  }
}

.fill {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: transparent;
}

@media screen and (max-width: 1284px) {
  .zenPayrollContainer {
    max-width: 90%;
  }
  .buttonContainer {
    margin-left: 24px;
    display: flex;
    gap: 10px;
    justify-content: flex-start;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
  }
  .buttonContainer::-webkit-scrollbar {
    display: none;
  }
  .button {
    flex: 0 0 auto;
    width: auto;
  }
  .button:last-child {
    margin-right: 24px;
  }
}

@media screen and (max-width: 800px){
  .centeredDetailContainer {
    flex-direction: column;
    margin: 0 24px;
  }
  .zenPayrollContainer {
    max-width: 100%;
    padding: 80px 0;
  }
  .topSection {
    padding: 0 24px;
  }
  .detailsContainer {
    width: 100%;
    padding: 32px 24px 0 24px;
  }
  .imageContainer {
    width: 100%;
    height: auto;
    margin-top: 40px;
  }
}
