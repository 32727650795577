.btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.btn.primary {
  color: var(--white);
  padding: 0.75rem 1.5rem;
  background: var(--blue-2);
  border-radius: 5.125rem;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: -0.015rem;
}

.btn.secondary {
  padding: 8px 20px;
  min-height: 36px;
  border-radius: 8px;
  background-color: var(--white-19);
  color: var(--white);
}
