.container {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding: 120px 0;
}
.detailsContainer {
  max-width: 960px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 12px;
  text-align: center;
}
.imageContainer {
  background-color: var(--fill-selection-only);
  border-radius: 16px;
  padding: 80px 120px;
}
.image {
  width: 100%;
  height: auto;
}
.mobileImage {
  display: none;
}
.cardArrowContainer {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.arrowSection {
  margin-top: 32px;
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  gap: 16px;
}
.circleDot {
  display: inline-block;
  width: 12px;
  height: 12px;
  background-color: var(--p-10);
  border-radius: 50%;
}
.activeCircleDot {
  background-color: var(--p-50);
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 48px;
}

.button {
  padding: 16px;
  height: 55px;
  background: transparent;
  color: var(--p-50);
  border: 1px solid var(--stroke-buttons-input);
  border-radius: 16px;
  position: relative;
  overflow: hidden;
  font-size: var(--font-size-18);
  line-height: var(--line-height-23);
  font-weight: var(--font-weight-500);
}

.button.active {
  color: var(--white);
  background-color: var(--p-50);
}
.nextBackIcon {
  cursor: pointer;
  color: #6188bbac;
  transition: all 400ms;
}
.nextBackIcon:hover {
  color: #6188bb;
  transition: all 400ms;
}

@media (min-width: 801px) and (max-width: 1284px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .detailsContainer {
    padding: 0 24px;
  }
  .imageContainer {
    margin: 0 24px;
    padding: 80px 24px;
  }
  .mobileImage {
    display: block;
    width: 100%;
    height: auto;
  }
  .image {
    display: none;
  }
}

@media screen and (max-width: 500px) {
  .imageContainer {
    min-width: auto;
    min-height: auto;
  }
  .buttonContainer {
    margin-left: 24px;
    display: flex;
    justify-content: flex-start;
    overflow-x: auto;
    scrollbar-width: none;
    -ms-overflow-style: none;
    white-space: nowrap;
  }
  .buttonContainer::-webkit-scrollbar {
    display: none;
  }
  .button {
    width: auto;
    height: auto;
    flex-shrink: 0;
  }
  .button span {
    white-space: break-spaces;
    text-align: justify;
  }
  .button:last-child {
    margin-right: 24px;
  }
}
