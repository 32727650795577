.mainContainer {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
}
.paddingMainContainer {
  padding: 120px 0;
}
.carouselPadding {
  padding: 48px 0;
}
.topTileDetails {
  display: flex;
  flex-direction: column;
  gap: 12px;
  max-width: 960px;
  margin: 0 auto;
  text-align: center;
}
.footerText {
  max-width: 812px;
  margin: 0 auto;
  color: var(--p-50);
  text-align: center;
}
.checkListContainer {
  display: flex;
  flex-direction: column;
  gap: 24px;
  text-align: start;
}
.checkList {
  display: flex;
  align-items: flex-start;
  gap: 12px;
  font-size: var(--font-size-18);
  line-height: var(--line-height-23);
  font-weight: var(--font-weight-400);
}
.bgFillSelection {
  background-color: var(--fill-selection-only);
}
.bgFillHighlight {
  background-color: var(--fill-highlight-only);
}
.zenHrisActiveBgColor {
  background-color: var(--p-50);
}
.zenHrisActiveColor {
  color: var(--p-50);
}
.loaderBarColor {
  background-color: var(--p-10);
}
.descText {
  margin-bottom: 48px;
  font-size: var(--font-size-h6);
  font-weight: var(--font-weight-600);
  line-height: var(--line-height-24);
  letter-spacing: -0.2px;
  color: var(--text-secondary);
}

@media (min-width: 800px) and (max-width: 1284px) {
  .topTileDetails, .mainContainer {
    max-width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .topTileDetails {
    padding: 0 24px;
  }
  .paddingMainContainer {
    padding: 80px 24px;
  }
}