.container {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding: 120px 0;
}
.topMainSection {
  display: flex;
  align-items: center;
}
.topSectionImageContainer {
  width: 50%;
  border-radius: 16px;
  flex-shrink: 0;
}
.topSectionImageContainer img {
  height: auto;
}
.topSectionDetails {
  margin-left: 120px;
}
.topSectionPara {
  margin-top: 16px;
}
.mainSectionImg {
  display: inline-block;
}
.topTextContainer {
  text-align: center;
  max-width: 838px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cardContainer {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;
  margin-top: 64px;
}
.card {
  background-color: var(--white);
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 24px;
}
.cardIconBox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 68px;
  height: 68px;
  background-color: var(--p-10);
  border-radius: 16px;
}
.cardDetails {
  display: flex;
  flex-direction: column;
  gap: 8px;
}
.cardDetails p {
  color: #191653;
}
.mb16 {
  margin-bottom: 16px;
}
.containerPadding {
  padding: 80px 0 120px 0;
}
.seconSectionContainer {
  background-color: var(--fill-selection-only);
}
.mobileMainSectionImg {
  display: none;
}

@media (min-width: 800px) and (max-width: 1284px) {
  .container {
    max-width: 90%;
  }
}

@media screen and (max-width: 800px) {
  .container {
    padding: 80px 24px;
  }
  .mobileMainSectionImg {
    display: inline-block;
    margin: 24px 0;
  }
  .cardContainer,
  .topMainSection {
    display: flex;
    flex-direction: column;
  }
  .topMainSection {
    text-align: center;
  }
  .card,
  .topSectionImageContainer {
    width: 100%;
  }
  .cardText2 {
    display: inline-block;
  }
  .topSectionDetails {
    margin: 0;
  }
  .mainSectionImg {
    display: none;
  }
  .btn {
    margin: 16px auto 0 auto;
  }
}
