.container {
  max-width: var(--max-screen-width);
  margin: 0 auto;
  padding: 120px 0px;
	display: flex;
	flex-direction: column;
	gap: 80px;
}

@media screen and (max-width: 800px) {
  .container {
    padding: 80px 24px;
  }
}
