.parentContainer {
  position: relative;
  top: -30px;
}
.container {
  background-color: var(--blue-1);
  width: 100%;
  padding-top: 7.5rem;
  /* padding-bottom: 7.5rem; */
  border-radius: 40px 40px 0px 0px;

  /* margin-bottom: -50px; */
}
.subContainer {
  max-width: var(--container-width-primary);
  margin: 0 auto;
}

.secondContainer {
  position: relative;
  bottom: -30px;
  width: 100%;
  border-radius: 40px 40px 0px 0px;
  background: linear-gradient(236deg, #2e239b 3.94%, #17134b 100.77%);
  padding-top: 7.5rem;
  /* margin-top: 2rem; */
  /* box-shadow: 3px 5px 8.4px 5px rgba(0, 0, 0, 0.28); */
  padding-bottom: 7.5rem;
}

@media screen and (max-width: 1200px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
}

@media only screen and (max-width: 1400px) {
  .subContainer {
    width: var(--container-responsive-width);
  }
}
