.bgFillHighlight {
  background-color: var(--fill-highlight-only);
}
.container {
  max-width: var(--max-screen-width);
  width: 100%;
  margin: 0 auto;
  padding: 120px 0;
}
.topMainSection {
  display: flex;
  align-items: center;
}
.topSectionImageContainer {
  width: 50%;
  border-radius: 16px;
  flex-shrink: 0;
}
.topSectionImageContainer img {
  height: auto;
}
.topSectionDetails {
  margin-left: 120px;
}
.topSectionPara {
  font-size: var(--font-size-18);
  line-height: var(--line-height-28);
  font-weight: var(--font-weight-400);
  color: var(--d-50);
  margin-top: 16px;
}
.mainSectionImg {
  display: inline-block;
}
.topTextContainer {
  text-align: center;
  max-width: 724px;
  width: 100%;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.cardContainer {
  display: flex;
  align-items: center;
  gap: 24px;
  margin-top: 64px;
}
.card {
  width: 50%;
  border: 1px solid var(--p-10);
  border-radius: 24px;
}
.imageContainer {
  background-color: var(--fill-selection-only);
  border-top-left-radius: 24px;
  border-top-right-radius: 24px;
  border-bottom: 1px solid var(--p-10);
  height: 294px;
  display: flex;
}
.cardImage {
  width: 100%;
  height: auto;
}
.imageCenter {
  justify-content: center;
  align-items: center;
}
.imageRight {
  justify-content: flex-end;
  align-items: end;
}
.cardDetails {
  display: flex;
  align-items: flex-start;
  gap: 16px;
  padding: 24px 32px;
}
.cardIconBox {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background-color: var(--white);
  border: 1px solid var(--d-10);
  flex-shrink: 0;
  box-shadow: 12px 8px 16px 0px #12121214;
}
.cardParagraph {
  color: var(--text-secondary);
  margin-top: 8px;
}
.btn {
  margin-top: 16px;
  padding: 10px 20px;
}
.btnIconBox {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  background-color: var(--white);
  width: 44px;
  height: 44px;
  border-radius: 16px;
}
.mobileMainSectionImg {
  display: none;
}

@media screen and (max-width: 800px) {
  .container {
    padding: 80px 24px;
  }
  .mobileMainSectionImg {
    display: inline-block;
    margin: 24px 0;
  }
  .cardContainer,
  .topMainSection {
    flex-direction: column;
  }
  .topMainSection {
    text-align: center;
  }
  .card,
  .topSectionImageContainer {
    width: 100%;
  }
  .topSectionDetails {
    margin: 0;
  }
  .mainSectionImg {
    display: none;
  }
  .btn {
    margin: 16px auto 0 auto;
  }
}

@media screen and (max-width: 600px) {
  .imageContainer {
    height: auto;
  }
  .cardIconBox {
    display: none;
  }
  .imageCenter {
    padding: 24px 16px;
  }
  .imageRight {
    padding: 48px 0 0 16px;
  }
}
