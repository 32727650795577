.container {
  margin-bottom: 7.5rem;
}
.headerContainer {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}
.title {
  color: var(--white);
  text-align: center;
  font-size: 3rem;
  line-height: 3.25rem; /* 108.333% */
  font-weight: 600;
  /* margin-top: 32px; */
  margin-bottom: 1.5rem;
}
.desc {
  color: var(--light-gray-1);
  font-size: 1.125rem;
  font-weight: 500;
  text-align: center;
  line-height: 1.75rem; /* 155.556% */
  letter-spacing: -0.01125rem;
  margin-bottom: 4rem;
}

.firstCard {
  display: flex;
  border-radius: 16px;
  padding: 0 50px;
  width: 100%;
  /* margin-top: 64px; */
  justify-content: space-between;
  background: var(--blue-6);
  border: 1px solid rgba(255, 255, 255, 0.05);
}
.textContent {
  width: 45%;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  padding-bottom: 50px;
}
.imageContainer {
  width: 45%;
}
.firstCardImage {
  width: 100%;
  height: 100%;
  object-fit: contain;
}
.title2 {
  color: var(--white);
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.25rem; /* 128.571% */
  letter-spacing: -0.0175rem;
  margin-bottom: 12px;
}

.desc2 {
  color: var(--white);
  font-size: 1rem;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
  font-weight: 400;
  letter-spacing: -0.16px;
}

.bottomCard {
  display: flex;
  flex-wrap: wrap;
  margin-top: 20px;
  gap: 20px;
}
.bottomSingleCard {
  padding: 60px;
  display: flex;
  min-width: 300px;
  flex: 1;
  justify-content: space-between;
  flex-direction: column;
  gap: 60px;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.05);
  background: var(--blue-6);
}
.bottomCardImageContainer {
  min-width: 300px;
  max-height: 667px;
  max-width: 650px;
}
.bottomCardImage {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.title3 {
  color: var(--white);
  font-size: 1.75rem;
  font-weight: 600;
  line-height: 2.25rem; /* 128.571% */
  letter-spacing: -0.0175rem;
  margin-bottom: 12px;
}

.desc3 {
  color: #fff;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5rem; /* 150% */
  letter-spacing: -0.01rem;
}

@media only screen and (max-width: 800px) {
  .firstCard {
    flex-direction: column-reverse;
    gap: 5rem;
  }
  .textContent,
  .imageContainer {
    width: 100%;
  }
}

@media only screen and (min-width: 680px) and (max-width: 900px) {
  .bottomSingleCard {
    min-width: 500px;
  }
}

@media only screen and (max-width: 400px) {
  .bottomSingleCard {
    min-width: 200px;
  }
  .bottomCardImageContainer {
    min-width: 200px;
  }
}

@media only screen and (max-width: 500px) {
  .firstCard {
    display: flex;
    border-radius: 16px;
    padding: 0;
    width: 100%;
    /* margin-top: 64px; */
    justify-content: space-between;
    background: var(--blue-6);
    border: 1px solid rgba(255, 255, 255, 0.05);
  }
  .textContent,
  .bottomCardTextContent {
    padding: 20px;
  }
  .bottomSingleCard {
    padding: 0;
    display: flex;
    min-width: 300px;
    flex: 1;
    justify-content: space-between;
    flex-direction: column;
    gap: 60px;
    border-radius: 16px;
    border: 1px solid rgba(255, 255, 255, 0.05);
    background: var(--blue-6);
  }
  .bottomCardImageContainer,
  .imageContainer {
    padding: 32px;
  }
  .title {
    font-size: 36px;
  }
  .title2, .title3 {
    font-size: 20px;
  }
  .desc,
  .desc2,
  .desc3 {
    font-size: 16px;
  }
}
